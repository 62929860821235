import { createContentTransformer as createContentTransformerOld } from './content/index.old.js';
import { createContentTransformer } from './content/index.js';
import configStore from '../../../utils/config-store/index.js';
import translator from '../../../utils/translator/index.js';
import formatPipe from './content/pipes/format.js';
import uuid from '../../../utils/uuid/index.js';
import flipper from '../../../utils/flipper/index.js';
import filterState from './state/filter.js';
import groupsState from './state/groups.js';
import paginationState from './state/pagination.js';
import bulkState from './state/bulk.js';
import bulkStateOld from './state/bulk-old.js';
import priorityState from './state/priority.js';
import serverState from './state/server.js';
import columnSettingsState from './state/column-settings.js';
import columnSettingsStateOld from './state/column-settings-old.js';

class EDatagridState {
  constructor(component) {
    this.requestRender = component.requestRender;
    this.utils = component.utils;

    this._refs = component.refs;

    this._isConnected = false;
    this._id = null;
    this._uuid = uuid();
    this._content = null;
    this._transformContent = flipper.isOn('ui_datagrid_groups') ?
      createContentTransformer() :
      createContentTransformerOld();
    this._columnDefinitions = [];
    this._actions = [];
    this._actionType = 'primary';
    this._itemActions = [];
    this._isBordered = false;
    this._testHelpers = false;
    this._translations = {};
    this._formats = {};
    this._isHorizontalScrollDisabled = false;
    this._isHorizontalScrollVisible = false;
    this._autofocus = false;
    this._language = configStore.config.language;
    this._highlightedKey = '';
    this._viewSettingsVisible = false;
    this._idKey = null;
    this._layout = 'table';

    this._filterState = filterState(this);
    this._paginationState = paginationState(this);
    this._bulkState = flipper.isOn('ui_datagrid_card_layout') ? bulkState(this) : bulkStateOld(this);
    this._priorityState = priorityState(this);
    this._serverState = serverState(this);
    this._columnSettingsState = flipper.isOn('ui_datagrid_card_layout') ?
      columnSettingsState(this) :
      columnSettingsStateOld(this);
    this._groupsState = groupsState(this);

    this._wrapperWidth = 0;
  }

  get filterState() {
    return this._filterState;
  }

  get paginationState() {
    return this._paginationState;
  }

  get bulkState() {
    return this._bulkState;
  }

  get priorityState() {
    return this._priorityState;
  }

  get serverState() {
    return this._serverState;
  }

  get columnSettingsState() {
    return this._columnSettingsState;
  }

  get groupsState() {
    return this._groupsState;
  }

  get isConnected() {
    return this._isConnected;
  }

  set isConnected(value) {
    this._isConnected = value;
  }

  get uuid() {
    return this._uuid;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
    this.requestRender();
  }

  get isBordered() {
    return this._isBordered;
  }

  set isBordered(value) {
    this._isBordered = value;
    this.requestRender();
  }

  get content() {
    return this._content;
  }

  set content(content) {
    this._content = content ? content.slice(0) : null;
    this._paginationState.pageNumber = Math.min(this._paginationState.pageNumber, this._paginationState.pageCount);
    this._paginationState.itemCount = content ? this._content.length : 0;
    this.requestRender();
  }

  get columnDefinitions() {
    return flipper.isOn('ui_datagrid_card_layout') ?
      this._columnDefinitions.filter((field) => { return field.layoutType === this._layout; }) :
      this._columnDefinitions;
  }

  set columnDefinitions(value) {
    this._columnDefinitions = value;
    this.requestRender();
  }

  get columnDefinitionsWithoutChildren() {
    return flipper.isOn('ui_datagrid_card_layout') ?
      this._columnDefinitions.filter((field) => (field.layoutType === this._layout) && !field.parentUuid) :
      this._columnDefinitions.filter(field => !field.parentUuid);
  }

  get itemActions() {
    return this._itemActions;
  }

  set itemActions(value) {
    this._itemActions = value;
    this.requestRender();
  }

  get actions() {
    return flipper.isOn('ui_datagrid_card_layout') ?
      this._actions.filter(action => action.layoutType === this._layout) :
      this._actions;
  }

  set actions(value) {
    this._actions = value;
    this.requestRender();
  }

  get actionType() {
    return this._actionType;
  }

  set actionType(value) {
    this._actionType = value ? value : 'primary';
    this.requestRender();
  }

  get testHelpers() {
    return this._testHelpers;
  }

  set testHelpers(value) {
    this._testHelpers = value;
    this.requestRender();
  }

  get isHorizontalScrollDisabled() {
    return this._isHorizontalScrollDisabled;
  }

  set isHorizontalScrollDisabled(value) {
    this._isHorizontalScrollDisabled = value;
    this.requestRender();
  }

  set isHorizontalScrollVisible(value) {
    if (this._isHorizontalScrollVisible === value) { return; }

    this._isHorizontalScrollVisible = value;
    this.requestRender();
  }

  get isHorizontalScrollActive() {
    return !this._isHorizontalScrollDisabled && this._isHorizontalScrollVisible;
  }

  get autofocus() {
    return this._autofocus;
  }

  set autofocus(value) {
    this._autofocus = value;
    this.requestRender();
    this._refs.searchInput?.focus();
  }

  get highlightedKey() {
    return this._highlightedKey;
  }

  set highlightedKey(value) {
    this._highlightedKey = value;
    this.requestRender();
  }

  get viewSettingsVisible() {
    return this._viewSettingsVisible;
  }

  set viewSettingsVisible(value) {
    this._viewSettingsVisible = value;
    this.requestRender();
  }

  get isHeaderVisible() {
    const isBulkVisible = this._bulkState.bulkActions.length &&
    this._bulkState.selectedItemsCount &&
    !this._priorityState.isPriorityModeOpen;

    const isHeaderVisible = !this._filterState.isSearchHidden ||
      this._actions.length ||
      this._filterState.filters.length ||
      isBulkVisible;

    return isHeaderVisible;
  }

  get translations() {
    return this._translations;
  }

  get visibleContent() {
    if (this._serverState.isServerSide && this._content) {
      const rawData = this._content.map(row => ({
        raw: row
      }));

      return formatPipe(rawData, [this._language, this._columnDefinitions, this._formats])
        .slice(0, this._paginationState.pageSize);
    }

    return this.transformedContent.content;
  }

  get layout() {
    return this._layout;
  }

  set layout(value) {
    if (this._layout === value) { return; }

    this._layout = value;
    this.utils.columnSettingsPopups = {};
    if (flipper.isOn('ui_datagrid_card_layout')) {
      this._bulkState.clear();
    }
    this.requestRender();
  }

  get transformedContent() {
    return this._transformContent({
      content: this._content,
      columnDefinitions: this.columnDefinitions,
      columnSettings: this._columnSettingsState.activeSettings,
      keyword: this._filterState.searchKeyword.keyword,
      filters: this._filterState.filters,
      filterValues: this._filterState.filterValues,
      groupKey: this._groupsState.contentKey,
      sortBy: this._filterState.sorting.key,
      order: this._filterState.sorting.order,
      pageSize: this._paginationState.pageSize,
      pageNumber: this._paginationState.pageNumber,
      formats: this._formats,
      hidePagination: this._paginationState.hidePagination,
      searchKeys: this._filterState.searchKeys,
      searchIncludes: this._filterState.searchIncludes,
      language: this._language,
      translations: this._translations
    });
  }

  get hasContent() {
    return this._content && this._content.length;
  }

  get isEmpty() {
    const hasColumn = this.columnDefinitions.length;
    const hasVisibleContent = this.hasContent && this.visibleContent.length;
    const hasVisibleColumn = this.columnSettingsState.hasVisibleColumn;

    return !hasColumn || !hasVisibleContent || !hasVisibleColumn;
  }

  get hasBulkActionBar() {
    if (this._bulkState.hasBulkToggle) {
      return this._bulkState.isBulkToggleActive;
    }

    return this._bulkState.hasSelectedItems;
  }

  get hasBulkCheckboxes() {
    if (this._bulkState.hasBulkToggle) {
      return this._bulkState.isBulkToggleActive && !this._priorityState.isPriorityModeOpen;
    }

    return this._bulkState.hasBulkActions && !this._priorityState.isPriorityModeOpen;
  }

  get hasPriorityRadioButtons() {
    return this._priorityState.isPriorityModeOpen;
  }

  get wrapperWidth() {
    return this._wrapperWidth;
  }

  set wrapperWidth(value) {
    this._wrapperWidth = value;
  }

  get isSetUp() {
    return this._content && this._content.length && this._columnDefinitions.length;
  }

  get idKey() {
    return this._idKey;
  }

  set idKey(value) {
    this._idKey = value;
    this.requestRender();
  }

  changeRowData(oldRowData, newRowData) {
    this._content = this._content.map(item => item === oldRowData ? newRowData : item);
  }

  updateLanguage() {
    this._language = configStore.config.language;
    this.requestRender();
  }

  updateFormats() {
    this._formats = {
      dateFormat: configStore.config.dateFormat,
      timeFormat: configStore.config.timeFormat
    };

    this.requestRender();
  }

  updateTranslations() {
    this._translations.searchTooltip = translator.translate('components.datagrid.search.tooltip');
    this._translations.searchPlaceholder = translator.translate('components.datagrid.search.placeholder');
    this._translations.filterShowAll = translator.translate('components.datagrid.filters.showAll');
    this._translations.columnSettingsTooltip = translator.translate('components.datagrid.columnSettings.tooltip');
    this._translations.columnSortTooltip = translator.translate('components.datagrid.columnSortTooltip');
    this._translations.columnSettingsVisibilityLabel =
      translator.translate('components.datagrid.columnSettings.visibility.label');
    this._translations.columnSettingsWidthLabel =
      translator.translate('components.datagrid.columnSettings.width.label');
    this._translations.columnSettingsResetLabel =
      translator.translate('components.datagrid.columnSettings.reset.label');
    this._translations.duration = {
      days: {
        short: translator.translate('components.duration.days.short'),
        long: translator.translate('components.duration.days.long')
      },
      hours: {
        short: translator.translate('components.duration.hours.short'),
        long: translator.translate('components.duration.hours.long')
      },
      minutes: {
        short: translator.translate('components.duration.minutes.short'),
        long: translator.translate('components.duration.minutes.long')
      },
      seconds: {
        short: translator.translate('components.duration.seconds.short'),
        long: translator.translate('components.duration.seconds.long')
      },
      milliseconds: {
        short: translator.translate('components.duration.milliseconds.short'),
        long: translator.translate('components.duration.milliseconds.long')
      }
    };

    this._translations.getBulkCheckboxLabel = (name) =>
      translator.translate('components.datagrid.bulkCheckboxLabel', [name]);
  }

  upsertColumnDefinition(options) {
    const column = this._getColumnByUuid(options.uuid);
    const definitionIndex = this._columnDefinitions.indexOf(column);

    if (definitionIndex > -1) {
      this._columnDefinitions = this._columnDefinitions.slice()
        .map((definition, index) => index === definitionIndex ? options : definition);
    } else {
      this._columnDefinitions = this._columnDefinitions.concat(options);
    }

    if (options.type === 'priority') {
      this._priorityState.priorityColumn = options;
    }

    this._columnDefinitions = this._order(this._columnDefinitions);
    this.requestRender();
  }

  deleteColumnDefinition(uuid) {
    this._columnDefinitions = this._columnDefinitions.filter(row => row.uuid !== uuid);
    this.requestRender();
  }

  getColumnDefinitionByColumnKey(contentKey) {
    return this._columnDefinitions.find(row => row.layoutType === this._layout && row.contentKey === contentKey);
  }

  upsertItemAction(options) {
    const actionIndex = this._itemActions.findIndex(action => action.uuid === options.uuid);

    if (actionIndex > -1) {
      this._itemActions = this._itemActions.slice()
        .map((action, index) => index === actionIndex ? options : action);
    } else {
      this._itemActions = this._itemActions.concat(options);
    }

    this._itemActions = this._order(this.itemActions);
    this.requestRender();
  }

  deleteItemAction(uuid) {
    this._itemActions = this._itemActions.filter(action => action.uuid !== uuid);
    this.requestRender();
  }

  upsertAction(options) {
    const actionIndex = this._actions.findIndex(action => action.uuid === options.uuid);

    if (actionIndex > -1) {
      this._actions = this._actions.slice()
        .map((action, index) => index === actionIndex ? options : action);
    } else {
      this._actions = this._actions.concat(options);
    }

    this.requestRender();
  }

  deleteAction(uuid) {
    this._actions = this._actions.filter(action => action.uuid !== uuid);
    this.requestRender();
  }

  _getColumnByUuid(uuid) {
    return this._columnDefinitions.find(row => row.uuid === uuid);
  }

  _order(datagridElements) {
    const isOrderSet = datagridElements.find(element => element.order);
    if (!isOrderSet) { return datagridElements; }

    return datagridElements.slice(0).sort((actualDatagridElement, previousDatagridElement) => {
      const actual = actualDatagridElement.order;
      const previous = previousDatagridElement.order;
      if (actual === undefined && previous === undefined) { return 0; }
      if (actual === undefined) { return 1; }
      if (previous === undefined) { return -1; }
      if (actual < previous) { return -1; }
      if (actual > previous) { return 1; }
      return 0;
    });
  }
}

export default EDatagridState;
